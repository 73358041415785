<template>
  <div>
    <div class="mb-2">
      <quote-status-bar
        :key="reRenderComponentKey"
        :item="order"
        :quote-status-bar="quoteStatusBar"
      />
    </div>
    <router-view
      :key="reRenderComponentKey"
      @setServiceType="setServiceType"
    />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
// eslint-disable-next-line import/no-cycle
import mainConfig from '@/views/main/orders/view/service/config'
import config from '../components/create/request-service-quote/config'

export default {
  name: 'Create',
  components: { QuoteStatusBar },
  data() {
    return {
      quoteStatusBarItems: this.quoteStatusBar,
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    serviceType() {
      return this.$store.state[this.MODULE_NAME].serviceType.value
    },
    serviceOrders() {
      return this.$store.state[this.MODULE_NAME].serviceOrdersForm
    },
    reRenderComponentKey() {
      return this.$store.state[this.MODULE_NAME].reRenderComponentKey
    },
  },
  watch: {
    serviceType(newVal) {
      this.changeServiceTypeByType(newVal)
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, {})
  },
  methods: {
    changeServiceTypeByType(type) {
      if (this.SERVICE_TYPE_SEND_OUT_SERVICE === type) {
        this.quoteStatusBarItems[1].title = 'Service Order'
        this.quoteStatusBarItems[2].title = 'Invoice'
      }
      if (this.SERVICE_TYPE_IN_HOUSE_SERVICE === type) {
        // const data = this.serviceOrders
        // if (data?.service_orders_enable_in_house) {
        //   this.quoteStatusBarItems[1].title = 'In-House Service'
        // }
        this.quoteStatusBarItems[1].title = 'In-House Service'
        this.quoteStatusBarItems[2].title = 'Bill'
      }
    },
    setServiceType(type) {
      this.changeServiceTypeByType(type)
    },
  },
  setup() {
    const { SERVICE_TYPE_SEND_OUT_SERVICE, SERVICE_TYPE_IN_HOUSE_SERVICE } = config()
    const { quoteStatusBar, MODULE_NAME } = mainConfig()

    return {
      MODULE_NAME,
      quoteStatusBar,
      SERVICE_TYPE_SEND_OUT_SERVICE,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
    }
  },
}
</script>
